import * as React from 'react';
import { Layout } from '../components/misc';

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <h2>404!</h2>
      </main>
    </Layout>
  );
};

export default IndexPage;
